<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">{{ $t('partner_companies') }}</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active">{{ $t('dashboard') }}</li>
                     <li class="breadcrumb-item active">{{ $t('partner_companies') }}</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <!-- /.row -->
            <div class="row">
               <div class="col-12">
                  <div class="card">
                     <div class="card-header">
                        <router-link
                           :to="{ name: 'partnerCompaniesCreate' }"
                           class="btn btn-success"
                        >
                           <i class="fas fa-plus-circle"></i> {{ $t('create_partner_company') }}
                        </router-link>

                        <div class="card-tools">
                           <div class="input-group input-group-sm pt-2" style="width: 350px;">
                              <input
                                 type="text"
                                 name="table_search"
                                 class="form-control float-right"
                                 placeholder="Buscar"
                                 v-model="searchQuery"
                                 @input="handleDelayedSearch">

                              <div class="input-group-append">
                                 <button type="submit" class="btn btn-default">
                                    <i class="fas fa-search"></i>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <!-- /.card-header -->

                     <div v-if="isLoading" class="d-flex justify-content-center">{{ $t('loading') }}</div>

                     <div v-else class="card-body table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                           <thead>
                           <tr>
                              <th>{{ $t('name') }}</th>
                              <th>{{ $t('date_of_creation') }}</th>
                              <th>{{ $t('is_highlighted_female') }}</th>
                              <th>{{ $t('city') }}/{{ $t('state') }}</th>
                              <th>{{ $t('action') }}</th>
                           </tr>
                           </thead>
                           <tbody>
                              <tr v-for="(partnerCompany ,index) in partnerCompanies" :key="index">
                                 <td>
                                    <div class="row justify-content-between" style="width: 450px;">
                                       <div class="col-4">
                                          <div class="timeline-item">
                                             <div class="timeline-body">
                                                <img
                                                   :src="partnerCompany.imageCoverUrl !== '' ? partnerCompany.imageCoverUrl : 'https://placehold.it/150x100'"
                                                   width="150"
                                                   height="100"
                                                   alt="..."
                                                >
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-8">
                                          <p
                                             class="h5 pl-2 wrap-text text-secondary text-bold font-italic"
                                             style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
                                          >
                                             {{ partnerCompany.name }}
                                          </p>
                                       </div>
                                    </div>
                                 </td>
                                 <td>{{ partnerCompany.createdAt }}</td>
                                 <td>{{ partnerCompany.isHighlighted ? $t('yes') : $t('no') }}</td>
                                 <td>{{ `${partnerCompany.city} - ${partnerCompany.state}` }}</td>
                                 <td>
                                    <div class="row">
                                       <router-link
                                          :to="{ name: 'partnerCompaniesEdit', params: { uuid: partnerCompany.uuid } }"
                                          class="btn btn-sm btn-outline-primary text-bold"
                                       >
                                          <i class="pt-1 fas fa-pencil-alt"></i>
                                          {{ $t('edit') }}
                                       </router-link>
                                       <button
                                          @click.prevent="showConfirmationModal(partnerCompany.uuid)"
                                          class="ml-3 btn btn-sm btn-outline-danger text-bold">
                                          <i class="pt-1 fas fa-trash-alt"
                                       ></i>
                                          {{ $t('exclude') }}
                                       </button>
                                    </div>
                                 </td>
                                 <ConfirmationModal
                                    :modalId="modalConfirmationActionId"
                                    @actionConfirmed="deletePartnerCompany"
                                    @actionCancelled="$(`#${modalConfirmationActionId}`).modal('hide');"
                                 />
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <!-- /.card-body -->

                     <div class="card-footer clearfix">
                        <pagination
                           v-if="!isSearching"
                           :totalPages="totalPages"
                           :currentPage="currentPage"
                           :isFirstPage="isFirstPage"
                           :isLastPage="isLastPage"
                           :adjacentPages="adjacentPages"
                           :tokenPages="tokenPages"
                           :isLoading="isLoadingPagination"
                           @page-selected="handlePageSelected"
                        />
                     </div>
                     <!-- /.card-footer -->

                  </div>
                  <!-- /.card -->
               </div>
            </div>
            <!-- /.row -->
         </div>
      </section>
      <!-- /.content -->
   </div>
</template>

<script>
import partnerCompanyService from "../services/partner-company-service";
import { useToast } from "vue-toastification";
import ConfirmationModal from "../../../components/ConfirmationModal.vue"
import { mapState, mapMutations, mapActions } from "vuex"
import Pagination from "../../../components/Pagination.vue";

export default {
   name: "PartnerCompanies",
   setup() {
      const toast = useToast()
      return { toast }
   },
   components: {
      Pagination,
      ConfirmationModal
   },
   data(){
      return {
         isLoadingPagination: false,
         selectedPartnerCompanyUuid: null,
         modalConfirmationActionId: "modal-confirmation-action-partnerCompany",
         searchTimeout: null,
         searchQuery: "",
         isSearching: false,
         adjacentPagesCount: 2
      }
   },
   async created() {
      this.isLoadingPagination = true;

      try {
         await this.getAllPaginatedPartnerCompanies();

         if (this.tokenPages.length === 0) {
            await this.getInfoPaginationPartnerCompanies();
         }

      } finally {
         this.isLoadingPagination = false;
      }
   },
   computed: {
      ...mapState({
         partnerCompanies: state => state.partnerCompany.partnerCompanies.data,
         isLoading: state => state.partnerCompany.partnerCompanies.loading,
         currentPage: state => state.partnerCompany.partnerCompanies.pagination.currentPage,
         tokenPages: state => state.partnerCompany.partnerCompanies.pagination.tokenPages,
         totalPages: state => state.partnerCompany.partnerCompanies.pagination.totalPages,
         allBenefits: state => state.benefit.benefits.allBenefits,
         allPartnerCompaniesBenefitsRelation: state => state.partnerCompany.partnerCompanies.allPartnerCompaniesBenefitsRelation
      }),
      adjacentPages() {
         const currentIndex = this.tokenPages.findIndex((page) => page.page === this.currentPage);

         const startIdx = Math.max(0, currentIndex - this.adjacentPagesCount);
         const endIdx = Math.min(this.tokenPages.length - 1, currentIndex + this.adjacentPagesCount);

         const pages = this.tokenPages.slice(startIdx, endIdx + 1);

         return pages;
      },
      isFirstPage() {
         return this.currentPage === 1;
      },
      isLastPage() {
         return this.currentPage === this.totalPages;
      }
   },
   methods: {
      ...mapActions(['getAllPaginatedPartnerCompanies', 'getInfoPaginationPartnerCompanies', 'searchPartnerCompanies',
                        'getAllBenefits', 'getAllPartnerCompaniesBenefitsRelation']),
      ...mapMutations({
         setCurrentPage: 'SET_PARTNER_COMPANY_CURRENT_PAGE'
      }),
      async deletePartnerCompany () {

         try {
            await this.getAllBenefits();
            await this.getAllPartnerCompaniesBenefitsRelation();

            await partnerCompanyService.destroy(this.selectedPartnerCompanyUuid, this.allBenefits, this.allPartnerCompaniesBenefitsRelation);

            $(`#${this.modalConfirmationActionId}`).modal('hide');

            await this.getAllPaginatedPartnerCompanies();

            this.toast.success(this.$t("excluded"));
         } catch (error) {
            $(`#${this.modalConfirmationActionId}`).modal('hide');
            this.toast.error(this.$t("exclude_failure"));
         }
      },
      async handleDelayedSearch() {
         clearTimeout(this.searchTimeout);

         this.searchTimeout = setTimeout(async () => {
            if (this.searchQuery.length > 0) {
               this.isSearching = true;
               await this.searchPartnerCompanies(this.searchQuery);
            } else {
               this.isSearching = false;
               await this.getAllPaginatedPartnerCompanies();
            }
         }, 1000);
      },
      async handlePageSelected (action) {
         let pageIndex;

         if (action === 'first' && !this.isFirstPage) {
            pageIndex = 1;
         } else if (action === 'prev' && !this.isFirstPage) {
            this.setCurrentPage(this.currentPage - 1);
            pageIndex = this.currentPage;
         } else if (action === 'next' && !this.isLastPage) {
            this.setCurrentPage(this.currentPage + 1);
            pageIndex = this.currentPage;
         } else if (action === 'last' && !this.isLastPage) {
            pageIndex = this.totalPages;
         } else if (typeof action === 'number') {
            pageIndex = action;
         }

         if (pageIndex) {
            const targetPage = this.tokenPages.find((page) => page.page === pageIndex);

            if (targetPage) {
               this.setCurrentPage(targetPage.page);
               await this.getAllPaginatedPartnerCompanies({ pageToken: targetPage.token });
            }
         }
      },
      showConfirmationModal(uuid) {
         this.selectedPartnerCompanyUuid = uuid
         $(`#${this.modalConfirmationActionId}`).modal('show')
      }
   }
}
</script>

<style scoped>

</style>
